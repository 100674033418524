import React from "react";
import "./App.css";
import { IResponse, IStory } from "./IData";
import InterviewResponse from "./Response";
import InterviewStory from "./Story";

function App() {
	const [localDataLoaded, setLocalDataLoaded] = React.useState(false);
	const [localData, setLocalData] = React.useState<string | null>(null);
	const [JSONData, setJSONData] = React.useState<Array<IStory | IResponse>>(
		[]
	);
	const collapseByDefault = false;
	const maxStatementLength = 250;
	const maxResponses = 4;
	const tags: string[] = Array.from(
		new Set(
			JSONData.reduce(
				(output, data) => {
					return [...data.tags, ...output];
				},
				[""]
			).filter((t) => t !== "")
		)
	).sort((a, b) =>
		a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
	);
	const [currentTag, setCurrentTag] = React.useState("");

	React.useEffect(() => {
		setLocalData(localStorage.getItem("JSONData"));
		setLocalDataLoaded(true);
	}, []);

	React.useEffect(() => {
		if (!JSONData.length) {
			if (localDataLoaded && localData) {
				let parsedData = JSON.parse(localData);
				if (parsedData && parsedData.stories) {
					setJSONData(parsedData.stories);
				}
			}
		}
	}, [localData, localDataLoaded, JSONData, setJSONData]);

	const loadJSON = () => {
		let newJson = prompt(`Enter validly formatted JSON:`);
		let parsedData = newJson ? JSON.parse(newJson) : {};
		if (parsedData && parsedData.stories) {
			localStorage.setItem("JSONData", newJson!);
			setJSONData(parsedData.stories);
		}
	};

	const clearJSON = () => {
		setJSONData([]);
		setLocalData(null);
		localStorage.setItem("JSONData", "");
	};

	const handleArticleClick = (dataSummary: string) => {
		setJSONData(
			JSONData.map((s) => {
				if (s.summary === dataSummary) {
					s.expanded = !s.expanded;
				}
				return s;
			})
		);
	};

	const handleTagUpdate = (newTag: string) => {
		// TODO: Refactor to useReducer
		setJSONData(
			JSONData.map((s) => {
				s.expanded = false;
				return s;
			})
		);
		setCurrentTag(newTag);
	};

	const upsertMaxResponseError = (
		values: number,
		label: string,
		errorArray: string[]
	) => {
		if (values > 4) {
			errorArray.push(
				`Consider limiting the # of ${label} to ${maxResponses}.`
			);
		}
		return errorArray;
	};

	return (
		<main className="App">
			<nav>
				<ul>
					{!JSONData.length ? (
						<li>
							<button onClick={() => loadJSON()}>
								Load JSON
							</button>
						</li>
					) : (
						<li>
							<button onClick={() => clearJSON()}>
								Clear JSON
							</button>
						</li>
					)}
					{tags.map((t) => (
						<li key={t}>
							<button onClick={() => handleTagUpdate(t)}>
								{t}
							</button>
						</li>
					))}
				</ul>
			</nav>
			<section>
				{!JSONData.length ? (
					<React.Fragment>
						<h1>{`Load Your JSON (see below example)`}</h1>
						<p>{`{"stories": [
						{"tags": ["Difficult project experience"],"summary": "Phrase to jog your memory", "situation": "the situation","task": "the task","actions": ["your first action","your second action"],"result": "the result","lessons": ["lesson (if applicable)"]},					
						{"tags": ["Greatest Strength"],"summary": "Your Strength Here","response": ["response p1", "response p2"],"lessons": ["lesson (if applicable)"]}
					]}`}</p>
					</React.Fragment>
				) : (
					<React.Fragment>
						<h1>{currentTag}</h1>
						{JSONData.filter(
							(s) => s.tags.indexOf(currentTag) > -1
						).map((data) => {
							let suggestions: string[] = [];
							let stringsToCheck: string[] = [
								data.summary,
								...(data.lessons ? data.lessons : []),
							];

							if ("task" in data) {
								stringsToCheck.push(
									data.summary,
									data.task,
									data.result,
									...data.actions
								);
								suggestions = upsertMaxResponseError(
									data.actions.length,
									"actions",
									suggestions
								);
							} else if ("response" in data) {
								stringsToCheck.push(...data.response);
								suggestions = upsertMaxResponseError(
									data.response.length,
									"responses",
									suggestions
								);
							}
							if (data.lessons) {
								suggestions = upsertMaxResponseError(
									data.lessons.length,
									"lessons",
									suggestions
								);
							}
							if (
								stringsToCheck.some(
									(statement) =>
										statement.length > maxStatementLength
								)
							) {
								suggestions.push(
									`Consider shortening statements that are longer than ${maxStatementLength} characters.`
								);
							}
							if (
								stringsToCheck.some(
									(statement) => statement.trim().length === 0
								)
							) {
								suggestions.push(`Enter all required data.`);
							}
							return (
								<article key={data.summary}>
									<h2
										onClick={() =>
											handleArticleClick(data.summary)
										}
									>
										{data.summary}
									</h2>
									{(data.expanded || !collapseByDefault) && (
										<React.Fragment>
											<h5>
												<strong>Tags:</strong>{" "}
												{data.tags.join(", ")}
											</h5>
											{suggestions.length > 0 && (
												<ul className="suggestions">
													{suggestions.map(
														(suggestion) => (
															<li
																key={suggestion}
																style={{
																	color: "red",
																	fontStyle:
																		"italic",
																}}
															>
																{suggestion}
															</li>
														)
													)}
												</ul>
											)}
											{"task" in data ? (
												<InterviewStory
													data={data}
													maxStatementLength={
														maxStatementLength
													}
												/>
											) : (
												<InterviewResponse
													data={data}
													maxStatementLength={
														maxStatementLength
													}
												/>
											)}
										</React.Fragment>
									)}
								</article>
							);
						})}
					</React.Fragment>
				)}
			</section>
		</main>
	);
}

export default App;
