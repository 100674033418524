import React from "react";
import "./App.css";
import { IStory } from "./IData";

export interface IInterviewStoryProps {
	data: IStory;
	maxStatementLength: number;
}

function InterviewStory({ data, maxStatementLength }: IInterviewStoryProps) {
	return (
		<React.Fragment>
			<h4>Situation:</h4>
			<p
				style={
					data.situation.length > maxStatementLength
						? { color: "red" }
						: {}
				}
			>
				{data.situation}
			</p>
			<h4>Task:</h4>
			<p
				style={
					data.task.length > maxStatementLength
						? { color: "red" }
						: {}
				}
			>
				{data.task}
			</p>
			<h4 style={data.actions.length > 4 ? { color: "red" } : {}}>
				Actions:
			</h4>
			<ul>
				{data.actions?.map((action) => (
					<li
						key={action}
						style={
							action.length > maxStatementLength
								? {
										color: "red",
								  }
								: {}
						}
					>
						{action}
					</li>
				))}
			</ul>
			<h4>Result:</h4>
			<p
				style={
					data.result.length > maxStatementLength
						? { color: "red" }
						: {}
				}
			>
				{data.result}
			</p>
			{data.lessons && data.lessons[0] && (
				<React.Fragment>
					<h4>Lessons Learned:</h4>
					<ul>
						{data.lessons.map((lesson) => (
							<li
								key={lesson}
								style={
									lesson.length > maxStatementLength
										? {
												color: "red",
										  }
										: {}
								}
							>
								{lesson}
							</li>
						))}
					</ul>
				</React.Fragment>
			)}
		</React.Fragment>
	);
}

export default InterviewStory;
