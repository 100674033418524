import React from "react";
import "./App.css";
import { IResponse } from "./IData";

export interface IInterviewResponseProps {
	data: IResponse;
	maxStatementLength: number;
}

function InterviewResponse({
	data,
	maxStatementLength,
}: IInterviewResponseProps) {
	return (
		<React.Fragment>
			<h4 style={data.response.length > 4 ? { color: "red" } : {}}>
				Response:
			</h4>
			<ul>
				{data.response?.map((r) => (
					<li
						key={r}
						style={
							r.length > maxStatementLength
								? {
										color: "red",
								  }
								: {}
						}
					>
						{r}
					</li>
				))}
			</ul>
			{data.lessons && data.lessons[0] && (
				<React.Fragment>
					<h4>Lessons Learned:</h4>
					<ul>
						{data.lessons.map((lesson) => (
							<li
								key={lesson}
								style={
									lesson.length > maxStatementLength
										? {
												color: "red",
										  }
										: {}
								}
							>
								{lesson}
							</li>
						))}
					</ul>
				</React.Fragment>
			)}
		</React.Fragment>
	);
}

export default InterviewResponse;
